import * as React from "react";
import PropTypes from "prop-types";

import Typography from '@mui/material/Typography';

import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import Grid from '@mui/material/Grid';

const FeatureGrid = ({ gridItems, gridWidth }) => (
	<>
		{gridItems.map((item) => (
			<Grid item sm={gridWidth}>
				<article key={item.text}>
					<PreviewCompatibleImage imageInfo={item} />
					<Typography variant='h4' className='title'>
						{item.heading}
					</Typography>
					<Typography variant='body1' className='description'>
						{item.text}
					</Typography>
				</article>
			</Grid>
		))}
	</>
);

FeatureGrid.propTypes = {
	gridItems: PropTypes.arrayOf(
		PropTypes.shape({
			image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
			text: PropTypes.string,
			heading: PropTypes.string,
		})
	),
};

export default FeatureGrid;
