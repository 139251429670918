// copy of templates/index-page.js

import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Link, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { alpha } from '@mui/material';
import theme from '../theme';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';

import Layout from '../components/Layout';
import Features from '../components/Features';
import BlogRoll from '../components/BlogRoll';
import FullWidthImageSimple from '../components/FullWidthImageSimple';
import RightHandImage from '../components/RightHandImage';
import Sidebar from '../components/Sidebar';
import Content, { HTMLContent } from '../components/Content';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';

import logo from '../img/logo.svg';
import HeroSectionTheir from '../components/sections/HeroSectionTheir';
import ALEiGNJonesieBkgrd from '../components/sections/ALEiGN-jonesieBkgrd';
import AboutSection from '../components/AboutSection';
import Mantra from '../components/sections/ALEiGN-mantra';
import UpdateList from '../components/UpdateList';
import instructions from '../img/ALEiGN-Instructions-OPT.gif';
import moshedKat from '../img/MoshedKatLogo.gif';
import cards from '../img/PlayingCards.jpg';
import signPost from '../img/ALEiGNd-signpost.jpg';
import relativityEscher from '../img/Relativity-escher.jpg';

// import ProductList from '../components/ProductList';

import Productive from '../img/being-productive.mp4';

const Section = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: alpha(theme.palette.background.paper, 0.3),
}));

const FrontContent = styled(Box)({
	marginTop: 'auto',
	marginBottom: '50px',
	margin: 'auto',
	// color: '#000',
});

const SectionContent = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	zIndex: 1,
	position: 'relative',
	height: '100%',
	overflow: 'hidden',
});

const StatBox = styled(Box)(({ theme }) => ({
	border: '6px solid',
	padding: 20,
	width: 'fit-content',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	position: 'relative',
	'.filter': {
		position: 'absolute',
		backgroundColor: alpha(theme.palette.background.default, 0.6),
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		textAlign: 'center;',
		zIndex: '-1',
		transition: 'all 0.5s ease',
	},
}));

const SuccessCard = styled(Paper)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	backgroundColor: theme.palette.paper,
	color: '#333',
	padding: theme.spacing(2),
	marginBottom: theme.spacing(3),
	'MuiTypography-h3': {
		lineHeight: 1,
	},
	'.MuiTypography-h6': {
		fontWeight: 600,
		fontSize: '1rem',
		lineHeight: 1,
		color: theme.palette.primary.main,
		paddingTop: theme.spacing(1),
	},
}));

const MainContent = styled(Box)({
	// marginTop: 'auto',
	// marginBottom: '50px',
	margin: 'auto 64px 50px',
});

const FeatureOne = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: theme.palette.paper,
	padding: theme.spacing(12),
}));

const CardOne = styled(Box)(({ theme, props }) => ({
	display: 'flex',
}));

const ScrollAnchor = styled(Box)({
	position: 'absolute',
});

// const Purple = purple[200];

// eslint-disable-next-line
export const ALEIGNTheirPageTemplate = ({
	image,
	title,
	heading,
	subheading,
	mainpitch,
	description,
	main,
	intro,
	content,
	contentComponent,
}) => {
	const heroImage = getImage(image) || image;
	const PageContent = contentComponent || Content;

	return (
		<>
			<HeroSectionTheir />
			<ScrollAnchor id='GDPsection' sx={{ top: 'calc(100vh - 70px)' }} />
			<FullWidthImageSimple
				img={heroImage}
				title={
					<>
						<Typography
							variant='h3'
							mb={0}
							sx={{ fontWeight: 700, textTransform: 'none' }}
							color='#fff'
						>
							Lets start with a problem.
						</Typography>
						<Typography variant='h2' color='primary' sx={{ fontWeight: 'inherit' }}>
							Let's go big!
						</Typography>
					</>
				}
			/>

			<Section px={8}>
				<FrontContent py={8} px={6} m={0} maxWidth='lg'>
					<Grid
						container
						sx={{ justifyContent: 'space-evenly', zIndex: 1, position: 'relative' }}
					>
						<Grid item xs={12} sm={4}>
							<StatBox
								ml={-12}
								mt={-10}
								sx={{
									paddingRight: '50px',
								}}
							>
								<Box className='filter' />
								<Typography variant='h5'>over time</Typography>
								<Typography variant='h3' sx={{ fontSize: '4rem' }}>
									7%
								</Typography>
							</StatBox>
							<StatBox
								ml={12}
								mt={-10}
								sx={{
									paddingLeft: '40px',
									color: '#000',
									'.filter': { backgroundColor: 'rgba(255, 255, 255, 0.5)' },
								}}
							>
								<Box className='filter' />
								<Typography variant='h5'>over budget</Typography>
								<Typography variant='h3' sx={{ fontSize: '4rem' }}>
									45%
								</Typography>
							</StatBox>
							<StatBox ml={6} mt={-3} sx={{ zIndex: 2 }}>
								<Box className='filter' />
								<Typography variant='h3' sx={{ fontSize: '4rem' }}>
									56%
								</Typography>
								<Typography variant='h5'>less value</Typography>
							</StatBox>
							<StatBox
								sx={{
									flexDirection: 'row',
									margin: '-20px 0 0 -90px',
									width: 'auto',
									padding: '50px 30px 30px',
									color: '#000',
									alignItems: 'flex-start',
									'.filter': { backgroundColor: 'rgba(255, 255, 255, 0.5)' },
								}}
							>
								<Box className='filter' />
								<Box pr={6}>
									<Typography variant='h3' align='right'>
										Black Swans
									</Typography>
									<Typography variant='h5' align='right' sx={{ fontSize: '0.9rem' }}>
										Threaten the existence of the company
									</Typography>
								</Box>
								<Typography variant='h3' sx={{ fontSize: '4rem', marginTop: '-14px' }}>
									17%
								</Typography>
							</StatBox>
						</Grid>
						<Grid item xs={12} sm={5}>
							<Typography variant='body1' my={0}>
								Research conducted by McKinsey Digital & the University of Oxford,
								found…
							</Typography>
							<Typography variant='h5' my={0}>
								I.T. projects cost overrun by{' '}
								<Typography
									variant='span'
									sx={{ fontSize: '2rem', fontWeight: 'bold' }}
								>
									MORE
								</Typography>{' '}
								than the
							</Typography>
							<Typography variant='h1'>G.D.P of Luxembourg!</Typography>
							<Typography variant='body1' my={2}>
								This research was conducted on more than 5,400 IT projects by McKinsey
								and the BT Centre for Major Programme Management at the University of
								Oxford.
							</Typography>
							<Typography>
								After comparing budgets, schedules, and predicted performance benefits
								with the actual costs and results, they found that these IT projects, in
								total, had a cost overrun of $66 billion, more than the GDP of
								Luxembourg.
							</Typography>
						</Grid>
					</Grid>
				</FrontContent>
			</Section>
			<SectionContent py={0} px={6} m={0}>
				<Box
					sx={{
						maxHeight: '400px',
						position: 'absolute',
						width: '100%',
						height: '100%',
						top: '0px',
						left: '0px',
						opacity: '0.2',
						img: {
							// bottom: 0,
							height: '100%',
							// left: 0,
							margin: 0,
							maxWidth: 'none',
							padding: 0,
							position: 'absolute',
							// right: 0,
							// top: '20px',
							width: '100%',
							objectFit: 'cover',
						},
					}}
				>
					<img src={instructions} alt='ALEIGN Instructions' />
				</Box>
				<Grid container justify='space-between' sx={{ zIndex: '1' }}>
					{/* <Grid item xs={0} md={7}></Grid> */}
					<Grid item xs={12} sm={12} p={8} sx={{ textAlign: 'center' }}>
						<Typography
							variant='h3'
							mb={2}
							sx={{ fontWeight: 700, textTransform: 'none' }}
							color='#fff'
						>
							Let's talk solutions{' '}
						</Typography>
						<Typography variant='h3' color='primary' sx={{ fontWeight: 'inherit' }}>
							If all else fails,
						</Typography>
						<Typography variant='h2' color='primary' sx={{ fontWeight: 'inherit' }}>
							Read the instructions!
						</Typography>
					</Grid>
				</Grid>
			</SectionContent>
			<Section className='section' px={8}>
				<FrontContent
					py={8}
					px={6}
					m={0}
					maxWidth='lg'
					sx={{ textAlign: 'center' }}
				>
					<Grid
						container
						spacing={3}
						sx={{ justifyContent: 'space-evenly', zIndex: 1, position: 'relative' }}
					>
						<Grid item xs={12} mb={4}>
							<Typography variant='h6' my={0} sx={{ textTransform: 'none' }}>
								According to the 2014 McKinley report (and others agree), these are
							</Typography>

							<Typography variant='h3'>
								The four most effective ways to enable a project’s success.
							</Typography>
						</Grid>
						<Grid item xs={12} sm={3}>
							<SuccessCard>
								<Typography variant='h3'>Strategy & Comms</Typography>
								<Typography>
									Focus on managing the project’s strategy and stakeholders rather than
									concentrating exclusively on budget and scheduling
								</Typography>
								<Typography variant='h6'>#comms #move2done! #context</Typography>
							</SuccessCard>
							<SuccessCard>
								<Typography variant='h3'>People</Typography>
								<Typography>
									Master the technology and project content by securing critical internal
									and external IT talent;
								</Typography>
								<Typography variant='h6'>#culture #comms</Typography>
							</SuccessCard>
						</Grid>
						<Grid item xs={12} sm={6} sx={{ position: 'relative' }}>
							<Box
								sx={{
									// position: 'absolute',
									width: '100%',
									height: '100%',
									top: '0px',
									left: '0px',
									// opacity: '0.2',
									// padding: '16px 16px 0',
									img: {
										height: '100%',
										width: '100%',
										objectFit: 'cover',
									},
								}}
							>
								{/* <FullWidthImageSimple img={cards} /> */}
								<img src={cards} alt='ALEIGN Kat' />
							</Box>
						</Grid>
						<Grid item xs={12} sm={3}>
							<SuccessCard>
								<Typography variant='h3'>Culture</Typography>
								<Typography>
									Build effective business teams by aligning their incentives and needs
									with the overall goals of project; and
								</Typography>
								<Typography variant='h6'>#culture #comms</Typography>
							</SuccessCard>
							<SuccessCard>
								<Typography variant='h3'>Tools & Processes</Typography>
								<Typography>
									Excel at core projectmanagement practices, such as adhering to short
									delivery cycles and conducting rigorous quality checks.
								</Typography>
								<Typography variant='h6'>#move2done!</Typography>
							</SuccessCard>
						</Grid>
					</Grid>
				</FrontContent>
			</Section>
			<SectionContent py={0} px={6} m={0}>
				<Box
					sx={{
						maxHeight: '400px',
						position: 'absolute',
						width: '100%',
						height: '100%',
						top: '0px',
						left: '0px',
						backgroundColor: '#fff',
						// opacity: '0.2',
						img: {
							// height: '100%',
							margin: 0,
							maxWidth: 'none',
							padding: 0,
							position: 'absolute',
							right: '-170px',
							top: '-80px',
							width: '60%',
							objectFit: 'cover',
						},
					}}
				>
					<Box
						sx={{
							background:
								'linear-gradient(to left, rgba(255,255,255,0) 30%,rgba(255,255,255,1) 49%,rgba(255,255,255,1) 100%)',
							/*background: transparent url("img/gradient.png") bottom right repeat-y;*/
							bottom: 0,
							height: '100%',
							left: 170,
							position: 'absolute',
							width: '80%',
							MozBackgroundSize: '100% 100%',
							OBackgroundSize: '100% 100%',
							WebkitBackgroundSize: '100% 100%',
							backgroundSize: '100% 100%',
							zIndex: 1,
						}}
					/>
					<img src={signPost} alt='ALEIGN Instructions' />
				</Box>
				<Grid container justify='space-between' sx={{ zIndex: '1' }}>
					{/* <Grid item xs={0} md={7}></Grid> */}
					<Grid item xs={12} sm={7} p={5} sx={{ textAlign: 'center' }}>
						<Typography
							variant='h3'
							mb={2}
							sx={{ fontWeight: 700, textTransform: 'none' }}
							color='secondary'
						>
							#context #comms #culture #move2done!
						</Typography>

						<Typography
							variant='h2'
							color='primary'
							sx={{ textTransform: 'none', fontWeight: '700' }}
						>
							“ALEiGNment”
						</Typography>
						<Typography
							variant='body1'
							color='primary'
							sx={{ fontWeight: 'inherit', pb: 4 }}
						>
							We’ve built Strategy, Context, Culture and a truck load of Tools into
							ALEiGN.
						</Typography>
						<Button
							variant='contained'
							component={Link}
							to='#contextSection'
							size='large'
							sx={{ px: 2, py: 1, fontSize: '0.8rem' }}
						>
							Let’s explore!
						</Button>
					</Grid>
				</Grid>
			</SectionContent>
			<SectionContent pt={12} pb={6} px={12} m={0}>
				<ScrollAnchor id='contextSection' sx={{ top: -70 }} />
				<Box
					sx={{
						maxHeight: '400px',
						position: 'absolute',
						width: '100%',
						height: '100%',
						top: '0px',
						left: '0px',
						opacity: '0.6',
						img: {
							// height: '100%',
							margin: 0,
							maxWidth: 'none',
							padding: 0,
							position: 'absolute',
							// right: 0,
							top: '-80px',
							// width: '100%',
							objectFit: 'cover',
						},
					}}
				>
					<img src={relativityEscher} alt='ALEIGN Instructions' />
				</Box>
				<Grid
					container
					spacing={4}
					// p={6}
					sx={{ zIndex: '1' }}
				>
					<Grid
						item
						xs={12}
						sm={5}
						p={6}
						sx={{
							textAlign: 'center',
							backgroundColor: theme.palette.background.paper,
							border: '4px solid #222',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<Typography
							variant='h3'
							mb={2}
							sx={{ fontWeight: 700, textTransform: 'none', fontStyle: 'italic' }}
							color={theme.palette.background.default}
						>
							“In software, as in art, the micro work and the macro vision should
							always be informed by one another.”
						</Typography>
						<Typography
							variant='h3'
							color='primary'
							sx={{ fontWeight: 'inherit', fontSize: '1rem' }}
						>
							JIRA is an antipattern – techcrunch.com
						</Typography>
						{/* <Typography variant='h2' color='primary' sx={{ fontWeight: 'inherit' }}>
							Follow the instructions!
						</Typography> */}
					</Grid>
					<Grid
						item
						xs={12}
						md={7}
						p={6}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
						}}
					>
						<Typography
							variant='h3'
							m={0}
							sx={{ backgroundColor: '#222', p: 4, pb: 1 }}
						>
							It’s a #context thing
						</Typography>
						<Typography
							variant='body1'
							m={0}
							sx={{ backgroundColor: '#222', p: 4, pt: 0, textTransform: 'none' }}
						>
							ALEiGN’s tiered domain struture provide the ability to roll up tasks in
							subdomains to higher level domains delivers <b>Marco</b> and <b>Micro</b>{' '}
							views of your; project &gt; department &gt; enterprise &gt; or
							organization. This allows unique insights and reporting on many levels.
						</Typography>
					</Grid>
					<Grid
						item
						xs={3}
						// md={7}
						p={0}
					>
						<Box
							sx={{
								// position: 'absolute',
								width: '100%',
								height: '100%',
								top: '0px',
								left: '0px',
								// opacity: '0.2',
								// padding: '16px 16px 0',
								img: {
									height: '100%',
									width: '100%',
									objectFit: 'cover',
								},
							}}
						>
							{/* <FullWidthImageSimple img={cards} /> */}
							<img src={cards} alt='ALEIGN Kat' />
						</Box>
					</Grid>
					<Grid
						item
						xs={3}
						// md={7}
						p={0}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							position: 'relative',
						}}
					>
						<Box
							sx={{
								// position: 'absolute',
								width: '100%',
								height: '100%',
								top: '0px',
								left: '0px',
								// opacity: '0.2',
								// padding: '16px 16px 0',
								img: {
									height: '100%',
									width: '100%',
									objectFit: 'cover',
								},
							}}
						>
							{/* <FullWidthImageSimple img={cards} /> */}
							<img src={cards} alt='ALEIGN Kat' />
						</Box>
					</Grid>
					<Grid
						item
						xs={3}
						// md={7}
						p={0}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							position: 'relative',
						}}
					>
						<Box
							sx={{
								// position: 'absolute',
								width: '100%',
								height: '100%',
								top: '0px',
								left: '0px',
								// opacity: '0.2',
								// padding: '16px 16px 0',
								img: {
									height: '100%',
									width: '100%',
									objectFit: 'cover',
								},
							}}
						>
							{/* <FullWidthImageSimple img={cards} /> */}
							<img src={cards} alt='ALEIGN Kat' />
						</Box>
					</Grid>
					<Grid
						item
						xs={3}
						// md={7}
						p={0}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							position: 'relative',
						}}
					>
						<Box
							sx={{
								// position: 'absolute',
								width: '100%',
								height: '100%',
								top: '0px',
								left: '0px',
								// opacity: '0.2',
								// padding: '16px 16px 0',
								img: {
									height: '100%',
									width: '100%',
									objectFit: 'cover',
								},
							}}
						>
							{/* <FullWidthImageSimple img={cards} /> */}
							<img src={cards} alt='ALEIGN Kat' />
						</Box>
					</Grid>
				</Grid>
			</SectionContent>
			<ALEiGNJonesieBkgrd />
			<SectionContent py={0} px={6} m={0}>
				<ScrollAnchor id='contextSection' sx={{ top: -70 }} />
				<Box
					sx={{
						maxHeight: '400px',
						position: 'absolute',
						width: '100%',
						height: '100%',
						top: '0px',
						left: '0px',
						opacity: '0.2',
						img: {
							// height: '100%',
							margin: 0,
							maxWidth: 'none',
							padding: 0,
							position: 'absolute',
							right: 0,
							top: '-80px',
							// width: '100%',
							objectFit: 'cover',
						},
					}}
				>
					<img src={moshedKat} alt='ALEIGN Instructions' />
				</Box>
				<Grid container justify='space-between' sx={{ zIndex: '1' }}>
					{/* <Grid item xs={0} md={7}></Grid> */}
					<Grid item xs={12} sm={12} p={8} sx={{ textAlign: 'center' }}>
						<Typography
							variant='h3'
							mb={2}
							sx={{ fontWeight: 700, textTransform: 'none' }}
							color='#fff'
						>
							Let's talk solutions{' '}
						</Typography>
						<Typography variant='h3' color='primary' sx={{ fontWeight: 'inherit' }}>
							If all else fails,
						</Typography>
						<Typography variant='h2' color='primary' sx={{ fontWeight: 'inherit' }}>
							Follow the instructions!
						</Typography>
					</Grid>
				</Grid>
			</SectionContent>
			<Mantra />
		</>
	);
};

ALEIGNTheirPageTemplate.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	heading: PropTypes.string,
	subheading: PropTypes.string,
	mainpitch: PropTypes.object,
	description: PropTypes.string,
	intro: PropTypes.shape({
		blurbs: PropTypes.array,
	}),
};

const ALEIGNTheirPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;
	const { markdownRemark: post } = data;

	return (
		<Layout>
			<ALEIGNTheirPageTemplate
				image={frontmatter.image}
				title={frontmatter.title}
				heading={frontmatter.heading}
				subheading={frontmatter.subheading}
				mainpitch={frontmatter.mainpitch}
				description={frontmatter.description}
				main={frontmatter.main}
				intro={frontmatter.intro}
				contentComponent={HTMLContent}
				content={post.html}
			/>
		</Layout>
	);
};

ALEIGNTheirPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default ALEIGNTheirPage;

export const pageQuery = graphql`
	query theirPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "aleign-theirPage" } }) {
			html
			frontmatter {
				title
				image {
					childImageSharp {
						gatsbyImageData(quality: 100, layout: FULL_WIDTH)
					}
				}
				heading
				subheading
				mainpitch {
					title
					description
				}
				description
				intro {
					blurbs {
						text
						heading
					}
					heading
					description
				}
				main {
					heading
					description
					image1 {
						alt
						image {
							childImageSharp {
								gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
							}
						}
					}
					image2 {
						alt
						image {
							childImageSharp {
								gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
							}
						}
					}
					image3 {
						alt
						image {
							childImageSharp {
								gatsbyImageData(width: 600, quality: 100, layout: FULL_WIDTH)
							}
						}
					}
				}
			}
		}
	}
`;
