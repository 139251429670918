import React from 'react';
import { Link } from 'gatsby';
import { styled } from '@mui/system';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import apaLogo from '../img/APA_M_H_POS_RGB_180.png';
import { Typography } from '@mui/material';

const Sidebar = ({ sidebarItems }) => (
	<>
		<Card variant='outlined' sx={{ mt: 0 }}>
			<div id='contactUs' />
			<CardHeader
				title='Our Services'
				sx={{ textAlign: 'center', backgroundColor: 'red' }}
			/>
			<CardContent>
				<List component='nav' aria-label='secondary mailbox folders'>
					{sidebarItems.map((item) => (
						<ListItem button key={item.text}>
							<ListItemText primary={item.heading} />
							<Divider sx={{ mt: 2 }} />
						</ListItem>
					))}
				</List>
			</CardContent>
			<CardActions>
				<Button color='primary' size='lg'>
					Find out more
				</Button>
			</CardActions>
		</Card>
		<Box sx={{ mt: 2 }}>
			<Typography variant='h3'>
				For all Appointments
				<br /> please call
			</Typography>
			<Typography variant='h4'>(08) 9279 5666</Typography>
			<img alt='APA Logo' src={apaLogo} />
		</Box>
	</>
);

export default Sidebar;
