import * as React from 'react';
import { styled } from '@mui/system';
import { alpha } from '@mui/material';

import { StaticImage } from 'gatsby-plugin-image';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';

// import separator from '../images/separatorWhite.png';

const Section = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	backgroundColor: alpha(theme.palette.background.paper, 0.3),
}));

const FrontContent = styled(Box)({
	marginTop: 'auto',
	marginBottom: '50px',
	margin: 'auto',
	// color: '#000',
});

function AboutSection() {
	return (
		<Section className='section' px={8}>
			<FrontContent px={8} maxWidth='lg' className='pItem pFnt'>
				<Box className='container' maxWidth='lg' py={4}>
					<Grid container justify='center'>
						<Grid item xs={12} sm={8}>
							<Typography
								variant='h3'
								my={2}
								sx={{ fontWeight: 700, textTransform: 'none' }}
							>
								ALEiGN
							</Typography>
							{/* {<img src={separator} className=' ' alt='separator' />} */}
							<Typography variant='body1' my={2}>
								<strong>Pronounced align,</strong> this cloud-based,{' '}
								<strong>humanistic-digital fusion tool</strong> Makes any process Better{' '}
								<em>(is there really enough 'hipster' in this intro?)</em>. We work with
								organisations looking to make the world a better place, build positive
								company culture and value their people as much as they do their
								customers.
							</Typography>
							<Typography variant='body1' my={2}>
								<strong>Originally conceived</strong> to allow consultants and their
								customers to execute on their strategic plan, it's the top to bottom
								transparency{' '}
								<strong>revealing real-world progress and sentiment</strong> that sets
								this tool apart from others in the market.
							</Typography>
							<Typography variant='body1' my={2}>
								Five years of design and development, over a dozen methodologies and
								frameworks, and <strong>an uncountable number of beers</strong> have
								gone into the creation of this product.{' '}
							</Typography>
							{/* <Stack
								direction='row'
								// justifyContent='center'
								alignItems='center'
								spacing={6}
								mt={4}
							>
								<Button
									size='lg'
									href='#'
									target='_blank'
									rel='noopener noreferrer'
									variant='aleign'
								>
									I get it, <br />
									show me the detail
								</Button>
								<Button
									size='lg'
									href=' '
									target='_blank'
									rel='noopener noreferrer'
									variant='aleign'
								>
									Interesting, <br />
									Tell me more
								</Button>
							</Stack> */}
						</Grid>
					</Grid>
				</Box>
			</FrontContent>
		</Section>
	);
}

export default AboutSection;
