// TS NOTES: a copy of FullWidthImage with added CTAs and buttons.
// created initally for Repair My Glasses landing page
// this have no props {title}{subtitle} (FullWidthImage does) but has children for easier large content managemment
// could prob do a better job with this and on;y use 1 conponent but this will do for now 

import React, { Children } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { GatsbyImage } from 'gatsby-plugin-image';

const Section = styled(Box)({
	position: 'relative',
	// minHeight: '80vh',
	display: 'flex',
	alignItems: 'center',
	// marginBottom: '50px',
	justifyContent: 'center',
	// top: '64px',
	// color: blueGrey
});

const FrontContent = styled(Box)({
	marginTop: '34px',
	marginBottom: '200px',
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	zIndex: 1,
});

export default function RightHandImage(props) {
	const {
		height = 400,
		img,
		title,
		subheading,
		imgPosition = 'top center',
		children,
	} = props;

	return (
		<Section className='heroWrapper'>
			<FrontContent maxWidth='lg' px={8} className='FntContent'>
				{img?.url ? (
					<img
						src={img}
						objectFit={'cover'}
						objectPosition={imgPosition}
						style={{
							gridArea: '1/1',
							// You can set a maximum height for the image, if you wish.
							height: height,
							width: '100%',
						}}
						// You can optionally force an aspect ratio for the generated image
						aspectratio={3 / 1}
						// This is a presentational image, so the alt should be an empty string
						alt=''
						formats={['auto', 'webp', 'avif']}
					/>
				) : (
					<GatsbyImage
						image={img}
						objectFit={'cover'}
						objectPosition={imgPosition}
						style={{
							gridArea: '1/1',
							// You can set a maximum height for the image, if you wish.
							maxHeight: height,
							position: 'absolute',
							width: '100%',
							bottom: 0,
							left: 0,
							height: '100%',
						}}
						layout='fullWidth'
						// You can optionally force an aspect ratio for the generated image
						aspectratio={3 / 1}
						// This is a presentational image, so the alt should be an empty string
						alt=''
						formats={['auto', 'webp', 'avif']}
					/>
				)}

				{children}
			</FrontContent>
		</Section>
	);
}

RightHandImage.propTypes = {
	img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	height: PropTypes.number,
	subheading: PropTypes.string,
};
