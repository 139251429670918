import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/system';
import { blueGrey, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';

import { GatsbyImage } from 'gatsby-plugin-image';

// const Section = styled(Box)({
// 	position: 'relative',
// 	minHeight: '80vh',
// 	display: 'flex',
// 	alignItems: 'center',
// 	marginBottom: '50px',
// 	justifyContent: 'center',
// 	// color: blueGrey
// });

const FrontContent = styled(Box)({
	display: 'flex',
	flexDirection: 'column',
	zIndex: 1,
	position: 'relative',
	height: '100%',
	overflow: 'hidden',
});

export default function FullWidthImageSimple(props) {
	const {
		height = 400,
		img,
		title,
		subheading,
		imgPosition = 'center center',
	} = props;

	return (
		// <Section className='heroWrapper'>
		<FrontContent px={8} maxWidth='100%' className='FntContent'>
			{img?.url ? (
				<img
					src={img}
					objectFit={'cover'}
					objectPosition={imgPosition}
					style={{
						// gridArea: '1/1',
						// You can set a maximum height for the image, if you wish.
						height: height,
						width: '100%',
					}}
					// You can optionally force an aspect ratio for the generated image
					aspectratio={3 / 1}
					// This is a presentational image, so the alt should be an empty string
					alt=''
					formats={['auto', 'webp', 'avif']}
				/>
			) : (
				<GatsbyImage
					image={img}
					objectFit={'cover'}
					objectPosition={imgPosition}
					style={{
						// gridArea: '1/1',
						// You can set a maximum height for the image, if you wish.
						maxHeight: height,
						position: 'absolute',
						width: '100%',
						top: 0,
						left: 0,
					}}
					layout='fullWidth'
					// You can optionally force an aspect ratio for the generated image
					aspectratio={3 / 1}
					// This is a presentational image, so the alt should be an empty string
					alt=''
					formats={['auto', 'webp', 'avif']}
				/>
			)}
			{(title || subheading) && (
				<Grid container justify='space-between' sx={{ zIndex: '1' }}>
					<Grid item xs={0} md={5}></Grid>
					<Grid item xs={12} sm={12} md={7} p={8}>
						{/* Any content here will be centered in the component */}
						{title && (
							<Typography variant='h1' sx={{ color: 'primary.contrastText' }}>
								{title}
							</Typography>
						)}
						{subheading && <Typography variant='h3'>{subheading}</Typography>}
					</Grid>
				</Grid>
			)}
		</FrontContent>
		// </Section>
	);
}

FullWidthImageSimple.propTypes = {
	img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	title: PropTypes.string,
	height: PropTypes.number,
	subheading: PropTypes.string,
};
